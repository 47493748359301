// App.js
import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase auth functions
import MapComponent from './components/MapComponent';
import MenuContainer from './components/containers/MenuContainer';
import HighlightedRouteContainer from './components/containers/HighlightedRouteContainer';
import FilterContainer from './components/containers/FilterContainer';
import './App.css';

function App() {
  const mapRef = useRef(null);
  const [highlightedRoute, setHighlightedRoute] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentUser, setCurrentUser] = useState(null); // Current user state
  const [isAuthChecked, setIsAuthChecked] = useState(false); // Track when auth is fully checked
  
  const adminUsers = ['W0tW3H4BS8cOFkRvNCTS4wuVdXB3', 'anotherAdminID']; // Add more admin IDs as needed
  
  // Function to check if the current user is admin
  const isAdmin = (user) => {
    return user && adminUsers.includes(user.uid);
  };

  // Firebase Authentication setup
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setIsAuthChecked(true); // Set auth as checked once the auth state changes
    });
    return () => unsubscribe(); // Cleanup on unmount
  }, []);

  // State to keep track of selected ratings
  const [selectedRatings, setSelectedRatings] = useState([1, 2, 3, 4, 5]);

  // State for distress data toggle
  const [distressEnabled, setDistressEnabled] = useState(false);

  // Handlers for filters
  const handleRatingChange = (ratings) => {
    console.log('Ratings changed:', ratings);
    setSelectedRatings(ratings);
  };

  // Handler for distress data toggle
  const handleDistressToggle = (enabled) => {
    console.log('Distress data toggled:', enabled);
    setDistressEnabled(enabled);
  };

  if (!isAuthChecked) {
    return <p>Loading...</p>;
  }

  return (
    <div className="App">
      
      <MenuContainer
        mapRef={mapRef}
        isAdmin={isAdmin(currentUser)}  // Pass the isAdmin flag
        setHighlightedRoute={setHighlightedRoute}
        currentUser={currentUser}  // Pass the current user to MenuContainer
      />

      <div className="main-content">
        <div className="sidepanelContainer">
          <div className="filterContainer">
            <FilterContainer
              onRatingChange={handleRatingChange}
              onDistressToggle={handleDistressToggle} // Add distress toggle handler
            />
          </div>
          <div className="routeContainer">
            <HighlightedRouteContainer
              highlightedRoute={highlightedRoute}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              selectedRatings={selectedRatings} // Pass selected ratings
            />
          </div>
        </div>
        
        <div className="mapContainer">
          <MapComponent 
            ref={mapRef} 
            selectedRatings={selectedRatings}  // Pass selected ratings
            distressEnabled={distressEnabled}  // Pass distress toggle state
            isAdmin={isAdmin(currentUser)}  // Pass isAdmin flag based on current user
            currentUser={currentUser}  // Pass the current user to MenuContainer
          />
        </div>
      </div>
    </div>
  );
}

export default App;
