import React, { useState, useEffect } from 'react';
import './EditPopup.css';

const EditPopup = ({ isOpen, onClose, currentVideoId, groupedData, refreshData }) => {
  const [startSample, setStartSample] = useState('');
  const [endSample, setEndSample] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [cvatRating, setCvatRating] = useState('');
  const [iriRating, setIriRating] = useState('');

  useEffect(() => {
    if (currentVideoId && groupedData[currentVideoId]) {
      const videoData = groupedData[currentVideoId];
      setCvatRating(videoData.items[0].cvatRating || '');
      setIriRating(videoData.items[0].iriRating || '');
    }
  }, [currentVideoId, groupedData]);

  // Move updateRating here
  const updateRating = async (videoId, startSample, endSample, cvatRating, iriRating, startTime, endTime) => {
    const data = {
      video_id: parseInt(videoId),
      cvat_rating: parseFloat(cvatRating),
      iri_rating: iriRating ? parseFloat(iriRating) : null
    };

    if (startSample && endSample) {
      data.start_sample = parseFloat(startSample);
      data.end_sample = parseFloat(endSample);
    } else if (startTime && endTime) {
      data.start_time = startTime;
      data.end_time = endTime;
    }

    try {
      const response = await fetch('https://140.203.17.132:443/update-rating', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Failed to update ratings');
      }
      refreshData(); // Call the refreshData function to refresh the data in the parent component
    } catch (error) {
      console.error('Error updating ratings:', error);
    }
  };

  const handleUpdateRating = () => {
    if (startSample && endSample) {
      updateRating(currentVideoId, startSample, endSample, cvatRating, iriRating, undefined, undefined);
    } else if (startTime && endTime) {
      updateRating(currentVideoId, undefined, undefined, cvatRating, iriRating, startTime, endTime);
    } else {
      console.error('Please provide either sample times or timestamps.');
    }
    onClose();
  };

  if (!isOpen) return null; // Don't render the popup if it isn't open

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h3>Edit Ratings</h3>
        <input
          type="text"
          placeholder="Start Sample"
          value={startSample}
          onChange={(e) => setStartSample(e.target.value)}
        />
        <input
          type="text"
          placeholder="End Sample"
          value={endSample}
          onChange={(e) => setEndSample(e.target.value)}
        />
        <input
          type="text"
          placeholder="Start Time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
        <input
          type="text"
          placeholder="End Time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
        />
        <input
          type="text"
          placeholder="CVAT Rating"
          value={cvatRating}
          onChange={(e) => setCvatRating(e.target.value)}
        />
        <input
          type="text"
          placeholder="IRI Rating"
          value={iriRating}
          onChange={(e) => setIriRating(e.target.value)}
        />
        <button className="update-rating-button" onClick={handleUpdateRating}>Update Rating</button>
      </div>
    </div>
  );
};

export default EditPopup;
