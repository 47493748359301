import React, { useState, useRef, useEffect } from 'react';
import userLogo from '../../uploads/userlogo.png';
import { auth, signInWithEmailAndPassword, signOut } from '../../firebaseConfig'; // Adjust this import if necessary
import { onAuthStateChanged } from "firebase/auth";
import './UserLoginPopup.css'

const UserDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
 
  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous error
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setEmail(''); // Clear email field on successful login
      setPassword(''); // Clear password field on successful login
    } catch (err) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      unsubscribe();
    };
  }, [isDropdownOpen]);

  return (
    <div className="user-dropdown" ref={dropdownRef}>
      <div className="user-placeholder" onClick={toggleDropdown}>
        <img src={user?.photoURL || userLogo} alt="User Logo" className="user-logo" />
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          {user ? (
            <>
              <div className="dropdown-item">
                <span className="dropdown-username">{user.email}</span>
              </div>
              <hr className="dropdown-separator" />
              <div className="dropdown-item">
                <button className="logout-button" onClick={handleLogout}>Log Out</button>
              </div>
            </>
          ) : (
            <form onSubmit={handleLogin} className="login-form">
              {error && <p className="login-error">{error}</p>}
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="login-button">Log In</button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
