import { useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import DistressPopupContainer from '../containers/DistressPopupContainer';

const useDistressMarkers = (mapRef, isAdmin) => {
  const clearDistressMarkers = useCallback((setDistressMarkers) => {
    setDistressMarkers((prevMarkers) => {
      prevMarkers.forEach(marker => marker.remove());
      return [];
    });
  }, []);

  const plotDistressMarkers = useCallback((data, setDistressMarkers) => {
    if (!mapRef.current) {
      console.error('mapRef is not initialized');
      return;
    }
    
    clearDistressMarkers(setDistressMarkers);

    const markers = data.map((item) => {
      const markerColor = item.type === "Solved" ? "green" : "purple";
      const marker = new mapboxgl.Marker({ color: markerColor })
        .setLngLat([parseFloat(item.location.split(',')[1]), parseFloat(item.location.split(',')[0])])
        .addTo(mapRef.current);

      const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(document.createElement('div'));
      marker.setPopup(popup);

      let root = null;
      popup.on('open', () => {
        if (!root) root = createRoot(popup._content);
        root.render(<DistressPopupContainer item={item} isAdmin={isAdmin} mapPopup={popup} />);
      });
      popup.on('close', () => {
        if (root) root.unmount();
        root = null;
      });

      return marker;
    });

    setDistressMarkers(markers);
  }, [mapRef, isAdmin, clearDistressMarkers]);

  return {
    plotDistressMarkers,
    clearDistressMarkers,
  };
};

export default useDistressMarkers;
