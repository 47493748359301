import React, { useRef, useEffect } from 'react';
import './HighlightedRouteContainer.css'

const HighlightedRouteContainer = ({ highlightedRoute, currentIndex, setCurrentIndex, selectedRatings }) => {
  const valueRef = useRef(null);

  useEffect(() => {
    if (valueRef.current) {
      const valueElement = valueRef.current;
      if (valueElement.scrollWidth > valueElement.clientWidth) {
        valueElement.classList.add('shrink');
      } else {
        valueElement.classList.remove('shrink');
      }
    }
  }, [highlightedRoute, currentIndex]);

  const navigateReadings = (direction) => {
    if (highlightedRoute && highlightedRoute.coordinates && highlightedRoute.coordinates.length > 0) {
      const newIndex = currentIndex + direction;
      if (newIndex >= 0 && newIndex < highlightedRoute.coordinates.length) {
        setCurrentIndex(newIndex);
      }
    }
  };

  const getColorByRating = (cvatRating) => {
    switch (cvatRating) {
      case 5: return '#00FF00';
      case 4: return '#0000FF';
      case 3: return '#FFFF00';
      case 2: return '#FFBF00';
      case 1: return '#FF0000';
      default: return '#FFFFFF';
    }
  };

  const handleFullscreen = () => {
    const img = document.querySelector('.frame-container img');
    if (img.requestFullscreen) {
      img.requestFullscreen();
    } else if (img.webkitRequestFullscreen) {
      img.webkitRequestFullscreen();
    } else if (img.msRequestFullscreen) {
      img.msRequestFullscreen();
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(1);
    let timeString = "";
    if (hours > 0) {
      timeString += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      timeString += `${minutes}m `;
    }
    timeString += `${remainingSeconds}s`;
    return timeString;
  };

  const calculateCumulativeDistance = (coordinates, index) => {
    if (!coordinates || coordinates.length < 2 || index === 0) return 0;

    let totalDistance = 0;

    for (let i = 1; i <= index; i++) {
      const lat1 = coordinates[i - 1]?.lat;
      const lng1 = coordinates[i - 1]?.lng;
      const lat2 = coordinates[i]?.lat;
      const lng2 = coordinates[i]?.lng;

      if (lat1 && lng1 && lat2 && lng2) {
        totalDistance += haversineDistance([lat1, lng1], [lat2, lng2]);
      }
    }

    return totalDistance.toFixed(2);
  };

  const haversineDistance = ([lat1, lng1], [lat2, lng2]) => {
    const R = 6371.071; // Radius of the Earth in kilometers
    const rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflng = (lng2 - lng1) * (Math.PI / 180); // Radian difference (longitudes)

    const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) ** 2 + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflng / 2) ** 2));
    return d; // Distance in kilometers
  };

  const handleTimelineClick = (event) => {
    if (highlightedRoute && highlightedRoute.coordinates) {
      const timelineRect = event.currentTarget.getBoundingClientRect();
      const clickX = event.clientX - timelineRect.left;
      const position = clickX / timelineRect.width;
      const clickedIndex = Math.floor(position * highlightedRoute.coordinates.length);

      if (clickedIndex >= 0 && clickedIndex < highlightedRoute.coordinates.length) {
        setCurrentIndex(clickedIndex);
      }
    }
  };

  const currentCoordinate = highlightedRoute?.coordinates?.[currentIndex];
  const cumulativeDistance = calculateCumulativeDistance(highlightedRoute?.coordinates, currentIndex);

  return (
    <div className="highlighted-route-popup">
      <div className={`frame-container ${currentCoordinate && currentCoordinate.frame ? 'no-border' : 'border'}`}>
        {highlightedRoute ? (
          <>
            {currentCoordinate && currentCoordinate.frame && (
              <button className="navigate-button" onClick={() => navigateReadings(-1)}>←</button>
            )}
            {currentCoordinate && currentCoordinate.frame ? (
              <img 
                src={`https://140.203.17.132:443/static/${currentCoordinate.frame}`} 
                alt="Frame" 
                className="highlighted-image" 
              />
            ) : (
              <div className="no-data-placeholder">No image available</div>
            )}
            {currentCoordinate && currentCoordinate.frame && (
              <>
                <div className="hover-buttons">
                  <div>CVAT Rating: {currentCoordinate.cvatRating}</div>
                  <button onClick={handleFullscreen}>Fullscreen</button>
                  <button onClick={() => {
                    const link = document.createElement('a');
                    link.href = `https://140.203.17.132:443/static/${currentCoordinate.frame}`;
                    link.download = currentCoordinate.frame;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}>Download</button>
                </div>
                <button className="navigate-button" onClick={() => navigateReadings(1)}>→</button>
              </>
            )}
          </>
        ) : (
          <div className="no-data-placeholder">Select a Route for Details</div>
        )}
      </div>
  
      {highlightedRoute && (
        <>
          <div className="timeline-container">
            <div className="timeline" onClick={handleTimelineClick}>
              {highlightedRoute.coordinates.map((coord, index) => {
                const isActive = selectedRatings.includes(coord.cvatRating);
                return (
                  <div
                    key={index}
                    className="timeline-segment"
                    style={{
                      backgroundColor: isActive ? getColorByRating(coord.cvatRating) : '#CCCCCC',
                      width: `${100 / highlightedRoute.coordinates.length}%`,
                    }}
                  />
                );
              })}
              <div
                className="timeline-indicator"
                style={{
                  left: `${(currentIndex / highlightedRoute.coordinates.length) * 100}%`
                }}
              />
            </div>
          </div>
          
          <div className="reading-details">
            <div className="detail-item">
              <p className="label">Time</p>
              <p className="value" ref={valueRef}>{formatTime(currentCoordinate?.sampleTime)}</p>
            </div>
            <div className="separator" />
            <div className="detail-item">
              <p className="label">Distance</p>
              <p className="value">{cumulativeDistance} km</p>
            </div>
          </div>
          
          <div className="rating-container">
            <div className="detail-item">
              <p className="label">Rating</p>
              <p className="value">{currentCoordinate?.cvatRating ?? '-'}</p>
            </div>
            <div className="detail-item">
              <p className="label">Roughness</p>
              <p className="value">{currentCoordinate?.iriRating ?? '-'}</p>
            </div>
          </div>
          
          <div className="right-section-map">
            <div className="overall-rating" style={{ backgroundColor: getColorByRating(highlightedRoute.averageRating) }}>
              <p>{highlightedRoute.averageRating ?? '-'}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );  
}

export default HighlightedRouteContainer;
