import React from 'react';
import './ManagePopup.css';

const ManagePopup = ({
  groupedData,
  isManagePopupOpen,
  setIsManagePopupOpen,
  handleFocusClick,
  deleteData,
  currentUser,
  isAdmin,
  setCurrentVideoId,
  setIsEditPopupOpen
}) => {
  if (!isManagePopupOpen) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={() => setIsManagePopupOpen(false)}>X</button>
        <div className="manage-container">
          {Object.keys(groupedData).map(video => (
            <div key={video} className="manage-item">
              <img src={`https://140.203.17.132:443/static/${groupedData[video].items[0].frame}`} alt="Thumbnail" />
              <div className="manage-details">
                <div className="details-text">
                  <p><strong>Title:</strong> {groupedData[video].description}</p>
                  <p><strong>Length:</strong> {Math.round((groupedData[video].video_length) / 60)} minutes</p>
                </div>
                <div className="details-buttons">
                  <button onClick={() => handleFocusClick(video)}>Focus</button>
                  {currentUser && isAdmin && (
                    <>
                      <button onClick={() => { setCurrentVideoId(video); setIsEditPopupOpen(true); }}>Edit</button>
                      <button onClick={() => deleteData(video)}>Delete</button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManagePopup;
