import mapboxgl from 'mapbox-gl';  // Import mapboxgl

// Clears the route for a specific video
export const clearRoute = (video, mapRef) => {
    if (mapRef.current.getSource(video)) {
      if (mapRef.current.getLayer(`${video}-points`)) {
        mapRef.current.removeLayer(`${video}-points`);
      }
      if (mapRef.current.getLayer(`${video}-line`)) {
        mapRef.current.removeLayer(`${video}-line`);
      }
      mapRef.current.removeSource(video);
    }
  };
  
  // Flys to the specified coordinates on the map
  export const flyToCoordinates = (coordinates, mapRef) => {
    if (!mapRef.current) return;
  
    const bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend([coord.lng, coord.lat]),
      new mapboxgl.LngLatBounds([coordinates[0].lng, coordinates[0].lat], [coordinates[0].lng, coordinates[0].lat])
    );
  
    mapRef.current.fitBounds(bounds, { padding: 50, animate: true });
  };
  
  // Gets the color based on cvatRating
  export const getColorByRating = (cvatRating) => {
    switch (cvatRating) {
      case 5:
        return '#00FF00';
      case 4:
        return '#0000FF';
      case 3:
        return '#FFFF00';
      case 2:
        return '#FFBF00';
      case 1:
        return '#FF0000';
      default:
        return '#FFFFFF';
    }
  };
  