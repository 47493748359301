// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

// Replace these with your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDIfGmvMb-_CzTBae0DKrdLt0YECqdWNJ8",
  authDomain: "your-auth-domain",
  projectId: "paveanalytics-95c79",
  storageBucket: "your-storage-bucket",
  messagingSenderId: "your-messaging-sender-id",
  appId: "1:774061674593:android:405af09a774461300d9a20"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, signInWithEmailAndPassword, signOut };
