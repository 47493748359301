import React, { useState } from 'react';
import logo from '../../uploads/logo.png';
import UploadPopup from '../Popups/UploadPopup';
import EditPopup from '../Popups/EditPopup';
import UserDropdown from '../Popups/UserLoginPopup';
import ManagePopup from '../Popups/ManagePopup';
import useFetchData from '../hooks/useFetchData';
import './MenuContainer.css';

const MenuContainer = ({
  mapRef,
  isAdmin,
  setHighlightedRoute,
  currentUser
}) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [isManagePopupOpen, setIsManagePopupOpen] = useState(false);
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const groupedData = useFetchData(currentUser?.uid, mapRef);

  const deleteData = async (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete video with ID ${id}?`);
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://140.203.17.132:443/data/${id}`, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Failed to delete data');
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleFocusClick = (video) => {
    const coordinates = groupedData[video].items;
    if (mapRef.current) {
      mapRef.current.flyToCoordinates(coordinates);
    }
    setIsManagePopupOpen(false); // Close the manage popup
    setHighlightedRoute({ video, coordinates, averageRating: getAverageRating(coordinates) });
  };

  const getAverageRating = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return 0;
    const totalRating = coordinates.reduce((acc, coord) => acc + coord.cvatRating, 0);
    return Math.round(totalRating / coordinates.length);
  };

  return (
    <div className="databaseContainer">
      <div className="header">
        <div className="right-section">
          <img src={logo} alt="Logo" className="header-logo" />
          <h1 className="pave-title">PavAnalytics</h1>
        </div>
        <div className="topbar-buttons">
          {currentUser && isAdmin && (
            <button className="uploadButton" onClick={() => setIsUploadPopupOpen(true)}>Upload</button>
          )}
          <button className="manageButton" onClick={() => setIsManagePopupOpen(true)}>Manage</button>
        </div>
        <UserDropdown />
      </div>

      <ManagePopup
        groupedData={groupedData}
        isManagePopupOpen={isManagePopupOpen}
        setIsManagePopupOpen={setIsManagePopupOpen}
        handleFocusClick={handleFocusClick}
        deleteData={deleteData}
        currentUser={currentUser}
        isAdmin={isAdmin}
        setCurrentVideoId={setCurrentVideoId}
        setIsEditPopupOpen={setIsEditPopupOpen}
      />

      <UploadPopup
        isOpen={isUploadPopupOpen}
        onClose={() => setIsUploadPopupOpen(false)}
        currentUser={currentUser}  // Pass the current user if needed
      />

      <EditPopup
        isOpen={isEditPopupOpen}
        onClose={() => setIsEditPopupOpen(false)}
        currentVideoId={currentVideoId}
        groupedData={groupedData}
      />
    </div>
  );
};

export default MenuContainer;
