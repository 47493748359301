// hooks/usePlotCoordinates.js
import { useCallback } from 'react';
import { getColorByRating } from '../utils/mapUtils';  // Import color utility

const usePlotCoordinates = (mapRef, selectedRatings) => {
  const plotCoordinates = useCallback((coordinates, video) => {
    // If no ratings are selected, show all the coordinates
    const filteredCoordinates = selectedRatings.length > 0
      ? coordinates.filter(coord => selectedRatings.includes(coord.cvatRating))
      : coordinates;

    // Clear the previous route
    if (mapRef.current.getSource(video)) {
      mapRef.current.removeLayer(`${video}-points`);
      mapRef.current.removeLayer(`${video}-line`);
      mapRef.current.removeSource(video);
    }

    if (filteredCoordinates.length === 0) return;

    // Create GeoJSON points for filtered coordinates
    const points = filteredCoordinates.map(coord => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [coord.lng, coord.lat],
      },
      properties: {
        color: getColorByRating(coord.cvatRating),
        video,
        cvatRating: coord.cvatRating
      },
    }));

    // Set a threshold distance to decide whether to draw a line between two points
    const maxDistance = 0.002; // Adjust this value as needed (units are degrees)

    // Create GeoJSON line segments between consecutive points that are within the threshold distance
    const lineSegments = [];
    for (let i = 1; i < filteredCoordinates.length; i++) {
      const prevCoord = filteredCoordinates[i - 1];
      const currentCoord = filteredCoordinates[i];
      const distance = Math.sqrt(
        Math.pow(currentCoord.lng - prevCoord.lng, 2) + Math.pow(currentCoord.lat - prevCoord.lat, 2)
      );

      // Only add the line segment if the distance is within the threshold
      if (distance <= maxDistance) {
        lineSegments.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [prevCoord.lng, prevCoord.lat],
              [currentCoord.lng, currentCoord.lat],
            ],
          },
          properties: {
            color: getColorByRating(currentCoord.cvatRating),
            video,
            cvatRating: currentCoord.cvatRating
          },
        });
      }
    }

    // Add a new source for the filtered points and lines
    mapRef.current.addSource(video, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [...points, ...lineSegments],
      },
    });

    // Add line segments (routes) layer
    mapRef.current.addLayer({
      id: `${video}-line`,
      type: 'line',
      source: video,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': ['get', 'color'], // Use color from properties
        'line-width': 5,
        'line-opacity': 0.3, // Default opacity for all routes
      },
    });

    // Add individual points (coordinates) layer
    mapRef.current.addLayer({
      id: `${video}-points`,
      type: 'circle',
      source: video,
      paint: {
        'circle-radius': 6,
        'circle-color': ['get', 'color'], // Use color from properties
        'circle-opacity': 0.3, // Default opacity for all points
      },
    });
  }, [mapRef, selectedRatings]);

  return plotCoordinates;
};

export default usePlotCoordinates;
