// components/DistressPopupContainer.js
import React from 'react';
import ReactDOM from 'react-dom';
import DistressPopup from '../Popups/DistressPopup';

const DistressPopupContainer = ({ item, isAdmin, mapPopup }) => {
  const onConfirm = async (id, type, comments) => {
    if (!isAdmin) return; // Non-admin users shouldn't update distress markers
    try {
      const response = await fetch('https://140.203.17.132:443/update-distress-type', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ id, type, comments }),
      });

      if (!response.ok) {
        throw new Error("Failed to update distress type");
      }

      const data = await response.json();
      console.log("Update successful:", data);

      if (type === "Solved") {
        mapPopup._marker.getElement().style.backgroundColor = "green";
      } else {
        mapPopup._marker.getElement().style.backgroundColor = "purple";
      }
    } catch (error) {
      console.error("Error updating distress report:", error);
    }
  };

  return mapPopup ? ReactDOM.createPortal(
    <DistressPopup
      item={item}
      isAdmin={isAdmin}
      onConfirm={onConfirm}
    />,
    mapPopup._content
  ) : null;
};

export default DistressPopupContainer;