import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import usePlotCoordinates from './hooks/usePlotCoordinates';
import useDistressMarkers from './hooks/useDistressMarkers';
import useFetchData from './hooks/useFetchData';
import 'mapbox-gl/dist/mapbox-gl.css';
import { flyToCoordinates, clearRoute } from './utils/mapUtils';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWRkaWVzaGVlaHkiLCJhIjoiY2w1bWd3OWlyMHRuNjNkbm1ybXN4cHFwYiJ9.kcDH28yltJvjN3004Pvjtg'; // Replace with your Mapbox access token

const MapComponent = forwardRef(({ selectedRatings, distressEnabled, isAdmin, currentUser }, ref) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapIsReady, setMapIsReady] = useState(false);
  const [distressMarkers, setDistressMarkers] = useState([]);  // State for distress markers

  const groupedData = useFetchData(currentUser?.uid);
  const plotCoordinates = usePlotCoordinates(mapRef, selectedRatings);
  const { plotDistressMarkers, clearDistressMarkers } = useDistressMarkers(mapRef, isAdmin);

  useImperativeHandle(ref, () => ({
    plotCoordinates,
    clearRoute: (video) => clearRoute(video, mapRef),
    flyToCoordinates: (coordinates) => flyToCoordinates(coordinates, mapRef),
  }));

  useEffect(() => {
    if (!mapRef.current && mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-6.2603, 53.3498],
        zoom: 7,
      });

      mapRef.current.on('load', () => {
        setMapIsReady(true);
      });
    }
  }, []);

  useEffect(() => {
    if (mapIsReady && groupedData && Object.keys(groupedData).length > 0) {
      Object.keys(groupedData).forEach((video) => {
        const coordinates = groupedData[video].items;
        if (coordinates && coordinates.length > 0) {
          plotCoordinates(coordinates, video);
        }
      });
    }
  }, [mapIsReady, groupedData, plotCoordinates]);

  useEffect(() => {
    const fetchDistressData = async () => {
      try {
        const response = await fetch('https://140.203.17.132:443/fetch-distress');
        const data = await response.json();
        plotDistressMarkers(data, setDistressMarkers);  // Pass setDistressMarkers here
      } catch (error) {
        console.error('Error fetching distress data:', error);
      }
    };

    if (mapRef.current && distressEnabled) {
      fetchDistressData();
    } else if (mapRef.current && !distressEnabled) {
      clearDistressMarkers(setDistressMarkers);  // Pass setDistressMarkers here
    }
  }, [distressEnabled, plotDistressMarkers, clearDistressMarkers]);

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
});

export default MapComponent;
