import { useState, useEffect, useCallback } from 'react';

const useFetchData = (userID) => {
  const [groupedData, setGroupedData] = useState({});
  const [isDataFetched, setIsDataFetched] = useState(false);  // Track if data has been fetched

  const fetchData = useCallback(async () => {
    try {
      let url = userID ? `https://140.203.17.132:443/data?userid=${userID}` : 'https://140.203.17.132:443/data?userid=public';
      const response = await fetch(url);
      console.log(response);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const dataList = await response.json();

      const grouped = dataList.reduce((acc, item) => {
        const {
          video_id,
          description,
          video_length,
          gps_lat_deg,
          gps_long_deg,
          cvat_rating,
          iri_rating,
          frame,
          image_timestamp_s,
          gps_alt_m,
          gps_2d_ms,
          gps_3d_ms,
          gps_days,
          gps_secs_s,
          gps_dop_deg,
          gps_fix_deg,
          sample_time_seg,
          y_acceleration_ms2,
          x_acceleration_ms2,
          z_acceleration_ms2,
          gyro_y_rads,
          gyro_x_rads,
          gyro_z_rads,
        } = item;

        if (!acc[video_id]) {
          acc[video_id] = { items: [], description, video_length };
        }

        const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
        let parsedSampleTime;
        if (timestampRegex.test(sample_time_seg)) {
          parsedSampleTime = sample_time_seg;
        } else {
          parsedSampleTime = parseFloat(sample_time_seg);
        }

        const relativeFramePath = frame.replace('/home/eddie/videos/', '');

        acc[video_id].items.push({
          lat: parseFloat(gps_lat_deg),
          lng: parseFloat(gps_long_deg),
          sampleTime: parsedSampleTime,
          cvatRating: parseFloat(cvat_rating),
          iriRating: iri_rating !== null ? parseFloat(iri_rating) : null,
          frame: relativeFramePath,
          imageTimestampS: parseFloat(image_timestamp_s),
          gpsAltM: parseFloat(gps_alt_m),
          gps2dMs: parseFloat(gps_2d_ms),
          gps3dMs: parseFloat(gps_3d_ms),
          gpsDays: parseFloat(gps_days),
          gpsSecsS: parseFloat(gps_secs_s),
          gpsDopDeg: parseFloat(gps_dop_deg),
          gpsFixDeg: parseFloat(gps_fix_deg),
          yAccelerationMs2: parseFloat(y_acceleration_ms2),
          xAccelerationMs2: parseFloat(x_acceleration_ms2),
          zAccelerationMs2: parseFloat(z_acceleration_ms2),
          gyroYRads: parseFloat(gyro_y_rads),
          gyroXRads: parseFloat(gyro_x_rads),
          gyroZRads: parseFloat(gyro_z_rads)
        });

        return acc;
      }, {});

      setGroupedData(grouped);
      setIsDataFetched(true);  // Mark that data has been fetched

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [userID]);

  useEffect(() => {
    if (userID && !isDataFetched) {
      fetchData();  // Fetch data only if it hasn't been fetched yet and userID is available
    }
  }, [fetchData, isDataFetched, userID]);

  return groupedData;
};

export default useFetchData;
